define("discourse/plugins/chat/discourse/components/chat-side-panel", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/lib/key-value-store", "discourse/plugins/chat/discourse/modifiers/chat/resizable-node", "discourse/plugins/chat/discourse/components/chat-side-panel-resizer", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _didInsert, _service, _keyValueStore, _resizableNode, _chatSidePanelResizer, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MIN_PANEL_WIDTH = 250;
  const STORE_NAMESPACE = "discourse_chat_side_panel_size_";
  class ChatSidePanel extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "chatStateManager", [_service.service]))();
    #chatStateManager = (() => (dt7948.i(this, "chatStateManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    store = (() => new _keyValueStore.default(STORE_NAMESPACE))();
    setupSize(element1) {
      if (this.site.mobileView) {
        return;
      }
      if (!this.chatStateManager.isFullPageActive) {
        return;
      }
      const validWidth1 = Math.min(this.store.getObject("width"), this.mainContainerWidth - MIN_PANEL_WIDTH);
      element1.animate([{
        width: element1.style.width
      }, {
        width: validWidth1 + "px"
      }], {
        duration: 0,
        fill: "forwards"
      });
      this.storeWidth(validWidth1);
    }
    static #_3 = (() => dt7948.n(this.prototype, "setupSize", [_object.action]))();
    didResize(element1, size1) {
      const mainPanelWidth1 = this.mainContainerWidth - size1.width;
      if (size1.width >= MIN_PANEL_WIDTH && mainPanelWidth1 >= MIN_PANEL_WIDTH) {
        element1.animate([{
          width: element1.style.width
        }, {
          width: size1.width + "px"
        }], {
          duration: 0,
          fill: "forwards"
        });
        this.storeWidth(size1.width);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "didResize", [_object.action]))();
    get mainContainerWidth() {
      return document.getElementById("main-chat-outlet").clientWidth;
    }
    storeWidth(width1) {
      this.store.setObject({
        key: "width",
        value: width1
      });
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.chatStateManager.isSidePanelExpanded}}
          <div
            class="chat-side-panel"
            {{didInsert this.setupSize}}
            {{resizableNode
              ".chat-side-panel-resizer"
              this.didResize
              (hash
                position=false vertical=false mutate=false resetOnWindowResize=true
              )
            }}
          >
            {{yield}}
    
            {{#if this.site.desktopView}}
              <ChatSidePanelResizer />
            {{/if}}
          </div>
        {{/if}}
      
    */
    {
      "id": "cGKNN6F8",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"chatStateManager\",\"isSidePanelExpanded\"]],[[[1,\"      \"],[11,0],[24,0,\"chat-side-panel\"],[4,[32,0],[[30,0,[\"setupSize\"]]],null],[4,[32,1],[\".chat-side-panel-resizer\",[30,0,[\"didResize\"]],[28,[32,2],null,[[\"position\",\"vertical\",\"mutate\",\"resetOnWindowResize\"],[false,false,false,true]]]],null],[12],[1,\"\\n        \"],[18,1,null],[1,\"\\n\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"          \"],[8,[32,3],null,null,null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&default\"],false,[\"if\",\"yield\"]]",
      "moduleName": "/var/www/forums/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-side-panel.js",
      "scope": () => [_didInsert.default, _resizableNode.default, _helper.hash, _chatSidePanelResizer.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatSidePanel;
});